import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import ResourceHeader from "@components/resources/header"
import PropTypes from "prop-types"
import { HTMLWrapper } from "@components/common/html-wrapper"

const AnalyticsCategoryTemplate = ({ data }) => {
  const analytics = data.analyticsCategory

  return (
    <Layout
      image={analytics.hero}
      fullWidth={true}
      body={analytics.body}
      date_created={analytics.date_created}
      date_updated={analytics.date_updated}
      seo={analytics.seo}
    >
      <ResourceHeader
        name={analytics.h1}
        subtitle={analytics.subtitle}
        image={analytics.hero}
        newCta
        shadow={false}
        kpi
        ctaCategory={`${analytics.name} Analytics`}
        links={[
          {
            text: "Resources",
            url: "/resources",
          },
          {
            text: "Analytics",
            url: "/resources/analytics",
          },
          {
            text: analytics.name,
          },
        ]}
      />

      <HTMLWrapper
        html={analytics.body}
        images={analytics.body_images}
        textContainer
      />
    </Layout>
  )
}

AnalyticsCategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default AnalyticsCategoryTemplate

export const pageQuery = graphql`
  query AnalyticsCategoryPageQuery($id: String!) {
    analyticsCategory(id: { eq: $id }) {
      name
      h1
      hero {
        cdn
        placeholder
        title
      }
      subtitle
      body
      body_images {
        cdn
        placeholder
        title
      }
      slug
      seo {
        title
        description
        tracking
        image {
          cdn
        }
        schema_template
        schema
      }
    }
  }
`
